<script>
import apkBG from "../../assets/images/background/apk_bg.png";

export default {
  name: "DownloadAPK",
  data(){
    return {
      apkBG,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    }
  },
  methods: {
    openLinkInNewTab() {
      const url = 'https://bitzvault.com/te/trustearns.apk';
      window.open(url, '_blank');
    }
  }
}
</script>

<template>
  <div class="text-center mt-5">
    <a href="#" @click="openLinkInNewTab" target="_blank" class="btn btn-light d-flex justify-content-center align-items-center gap-3" >
      <img :src="apkBG" alt="apk" style="width:50px;height:50px;object-fit:contain">
      Download the Android App
    </a>
  </div>
</template>

<style scoped>

</style>