<template>
    <div class="row justify-content-center min-vh-100 align-items-center signupBg">
        <div class="col-12 col-md-6 col-lg-4 sBlock">
            <div class="authincation-content">
                <div class="row no-gutters">
                    <div class="col-xl-12">
                        <div class="auth-form">
                            <div class="text-center mb-3">
                                <a href="/">
                                    <img src="../../assets/images/logo.png" alt="">
                                </a>
                            </div>
                            <h4 class="text-center mb-4">Get started</h4>
                            <p class="text-center font-weight-bold text-black mb-4">It only takes a minute to set up</p>
                            <form @submit.prevent="validateInput" ref="register">
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Username</strong></label>
                                    <input type="text" class="form-control" placeholder="username" v-model="user.data.name">
                                </div>
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Email</strong></label>
                                    <input type="email" class="form-control" placeholder="mail@example.com" v-model="user.data.email">
                                </div>
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Phone</strong></label>
                                    <input type="tel" class="form-control" placeholder="+123 456 789" v-model="user.data.phone">
                                </div>
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Country</strong></label>
                                    <country-select :autocomplete="true" style="height:40px" class="form-control" v-model="user.data.country" :country="user.data.country" />
                                </div>
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Region</strong></label>
                                    <region-select :autocomplete="true" :regionName="true" style="height:40px" class="form-control" v-model="user.data.region" :country="user.data.country" :region="user.data.region" />
                                </div>
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Password</strong></label>
                                    <input type="password" minlength="8" class="form-control" value="********" v-model="password">
                                </div>
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Retype Password</strong></label>
                                    <input type="password" minlength="8" class="form-control" value="********" v-model="repass">
                                </div>
                                <div class="form-group" v-if="referrer_data.id">
                                    <label class="mb-1 text-black"><strong>Referred by</strong></label>
                                    <input type="text" class="form-control" :value="referrer_data.data.email" disabled>
                                </div>
                                <div class="text-center mt-4">
                                    <button type="submit" class="btn btn-primary btn-block">Sign me up</button>
                                </div>
                            </form>
                            <div class="new-account mt-3">
                                <p>Already have an account?
                                    <router-link class="text-primary" :to="{name: 'Login'}">Sign in</router-link>
                                </p>
                            </div>
                          <DownloadAPK/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import User from "../../models/user";
import methodsMixin from "../../utils/methodsMixin";
import DownloadAPK from "@/components/home/DownloadAPK.vue";

export default {
    name: "Register",
  components: {DownloadAPK},
    data(){
        return {
            user: new User(),
            password: '', repass: '',
            referrer_data: {id: '', data: {}}
        }
    },
    mixins: [methodsMixin],
    methods: {
        async proceed(){
            const loader = this.$loading.show({container: this.$refs.register});
            if(this.referrer_data.id){
                this.user.data.referredBy = this.referrer_data.id;
            }
            const response = await this.$store.dispatch('auth/register', {
                user: this.user.data,
                password: this.password
            });
            loader.hide();
            if(response.status){
                toastr.success("Registration successful");
                return this.$router.push({name: 'Dashboard'})
            }else{
                toastr.error(response.message, 'Error')
            }
        },
        validateInput(){
            if(!this.user.data.name || !this.user.data.country || !this.user.data.region || !this.user.data.phone ||
                !this.password || !this.repass
            ){
                return toastr.warning("Fill in all fields", "Oops!")
            }
            if(!this.validateEmail(this.user.data.email)){
                return toastr.warning("Enter a valid email address", "Oops!")
            }
            if(this.user.data.phone.length < 4){
                return toastr.warning("Enter a valid phone number", "Oops!")
            }
            if(this.password.length < 8){
                return toastr.warning("The password length should be greater than 7 characters", "Oops!")
            }
            if(this.password !== this.repass){
                return toastr.warning("Passwords do not match", "Oops!")
            }
            return this.proceed()
        },
        async checkReferral(){
            if(this.$route.params.referral_id){
                const response = await this.$store.dispatch('user/getUser', this.$route.params.referral_id);
                if(response.status){
                    this.referrer_data = response.data;
                }
            }
        }
    },
    mounted() {
        this.checkReferral();
    }
}
</script>

<style scoped>
.signupBg{
  position: relative;
  background: url('../../assets/images/background/signupBg.jpg') no-repeat center center;
  background-size: cover;
}
.signupBg:after{
  content:"";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
}
.sBlock{
  z-index: 3;
}
.authincation-content{
  background-color: rgba(255,255,255,0.8);
}
</style>